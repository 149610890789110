import axios from "../../axios.js";

const endpoints = {
    registration: '/auth/register',
    authentication: '/auth/login',
    validateUser: '/account/user/verify',
    logout: '/account/user/logout',
    address: '/account/user/address',
    avatar: '/account/user/update-avatar',
    removeAvatar: '/account/user/remove-avatar',
    forgotPassword: '/auth/forgot-password',
    resetPasswordToken: '/auth/reset-password-token',
    resetPassword: '/auth/reset-password'
}

const tokenName = '_howofwhat_token'

const state = {
    name: '',
    email: '',
    phone: '',
    avatar: '',
    isSpecial: 0,
    member_since: '',
    address: '',
    street: '',
    city: '',
    state: '',
    country: '',
    zip:'',
    token: localStorage.getItem(tokenName) || ''
}
const actions = {
    validateUser: ({ commit }) => {
        return new Promise((resolve, reject)=>{  
            if(!state.token) {
                // reject({
                //     error: 'Token not found!'
                // });
                commit('authError');
                return ;
            }      
            //axios.get(endpoints.validateUser, {'headers': { 'Authorization': 'Bearer '+ state.token }})
            axios.get(endpoints.validateUser)
            .then(resp => {       
                if(resp.status === 200){
                    commit('setUserData', resp.data.data);
                    resolve(resp);
                }
            })
            .catch(err => {
                commit('authError', err);
                reject(err);
            });
        })
    },
    registration: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            axios.post(endpoints.registration, payload)
            .then(resp => {
                if(resp.status === 200) {
                    resolve(resp);
                    commit('authSuccess', resp.data);
                    commit('setUserData', resp.data.thrubit_auth_user);
                }
                else
                    reject(resp);            
            })
            .catch(err => {
                reject(err);
            });
            
        });
    },
    authentication: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            axios.post(endpoints.authentication, payload)
            .then(resp => {
            if(resp.status === 200) {
                commit('authSuccess', resp.data);
                commit('setUserData', resp.data.auth_user);
                resolve(resp);
            }
            else
                reject(resp);            
            })
            .catch(err => {
                commit('authError', err);
                reject(err);
            });
            
        });
    },
    logout: ({ commit }) => {
        return new Promise((resolve, reject) => {
            axios.post(endpoints.logout)
            .then(resp=>{
                if(resp.status === 200) { 
                    commit('authLogout');
                    resolve(resp);
                }
                else
                    reject(resp); 
            })
            .catch(err => {
                commit('authError', err);
                reject(err);
            });
        });
    },
    userAddress: ({commit}) => {
        return new Promise((resolve, reject) => {
            axios.post(endpoints.address)
            .then(resp=>{
                if(resp.status === 200) { 
                    commit('setUserAddress', resp.data.data);
                    resolve(resp.data.data);
                }
                else
                    reject(resp); 
            })
            .catch(err => {
                //commit('authError', err);
                reject(err);
            });
        });
    },
    userAvatar: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            let config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
            axios.post(endpoints.avatar, payload, config)
            .then(resp=>{
                if(resp.status === 200) { 
                    commit('setUserData', resp.data.data);
                    resolve(resp.data);
                }
                else
                    reject(resp); 
            })
            .catch(err => {
                reject(err);
            });
        });
    },
    removeAvatar: ({ commit }) => {
        axios.post(endpoints.removeAvatar)
        .then(resp=>{
            if(resp.status === 200) { 
                commit('setUserData', resp.data.data);
                resolve(resp.data);
            }
            else
                reject(resp); 
        })
        .catch(err => {
            reject(err);
        });
    },
    forgotPassword: ({commit}, payload) => {
        return new Promise((resolve, reject)=>{
            axios.post(endpoints.forgotPassword, payload)
            .then(resp => {
                if(resp.status === 200) {
                    resolve(resp);
                }
                else
                    reject(resp);            
            })
            .catch(err => {
                reject(err);
            });
        })
    },
    validateResetPasswordToken: ({commit}, payload) => {
        return new Promise((resolve, reject)=>{
            axios.post(endpoints.resetPasswordToken, payload)
            .then(resp => {
                if(resp.status === 200) {
                    resolve(resp);
                }
                else
                    reject(resp);            
            })
            .catch(err => {
                reject(err);
            });
        })
    },
    resetPassword: ({commit}, payload) => {
        return new Promise((resolve, reject)=>{
            axios.post(endpoints.resetPassword, payload)
            .then(resp => {
                if(resp.status === 200) {
                    resolve(resp);
                }
                else
                    reject(resp);            
            })
            .catch(err => {
                reject(err);
            });
        })
    }
};

const mutations = {
    setUserData: (state, res) => {
        state.name = res.name
        state.email = res.email
        state.phone = res.phone
        state.avatar = res.avatar
        state.isSpecial = res.is_special
        state.member_since = res.member_since
    },
    setUserAddress: (state, res) => {
        state.address = res.address
        state.street = res.street
        state.city = res.city
        state.state = res.state
        state.country = res.country
        state.zip = res.zip
    },
    authSuccess: (state, res) => {
        state.token = res.token;      
        localStorage.setItem(tokenName, res.token);        
        //axios.defaults.headers.common['Authorization'] = 'Bearer '+resp._token
    },
    authError: state => {
        state.token = "";
        localStorage.removeItem(tokenName);
    },
    authLogout: state => {
        state.token = "";
        localStorage.removeItem(tokenName);
        //delete axios.defaults.headers.common['Authorization'];
    },
    
    // setTokenExpiry: (state, payload) =>{
    //     state.tokenExp = payload
    // }
};

const getters = {
    storeToken: state => state.token,
    userName: state => state.name,
    userEmail: state => state.email,
    userPhone: state => state.phone,
    userDP: state => state.avatar,
    userSpecial: state => state.isSpecial,
};
export default {
    state,
    getters,
    actions,
    mutations
};