import axios from "../../axios.js";

const state = {
    siteTitle: '',
    siteEmail: '',
    sitePhone: '',
    additional_phone: '',
    siteAddress: '',
    tagline: '',
    currency: '',
    currency_symbol: '',
    header_script: '',
    footer_script:'',
    body_script: '',
    happy_students: '',
    online_courses: '',
    professors: '',
    //gallery_show: ''
}
const actions = {
    getConfigData:({commit}) => {
        return new Promise((resolve, reject)=>{
            axios.get('/configuration')
            .then(res=>{
                //console.log(res)
                if(res.status === 200){
                    commit('setConfigData', res.data.data);
                    resolve(res.data);
                }
                // else                
                //     commit('pageError', err);
            })
            .catch(err => {
                reject(err);
            });
        })
    }
}
const getters = {
    siteTitle: state => state.siteTitle,
    siteEmail: state => state.siteEmail,
    sitePhone: state => state.sitePhone,
    siteAddress: state => state.siteAddress,
    happy_students: state => state.happy_students,
    online_courses: state => state.online_courses,
    professors: state => state.professors
};

const mutations = {
    setConfigData: (state, res) => {
        state.siteTitle = res.site_title;
        state.siteEmail = res.site_email;
        state.sitePhone = res.site_phone;
        state.siteAddress = res.address;
        state.additional_phone = res.additional_phone;
        state.tagline = res.tag;
        state.currency = res.currency;
        state.currency_symbol = res.currency_symbol;
        state.header_script = res.header_script;
        state.body_script = res.body_script;
        state.footer_script = res.footer_script;
        state.happy_students = res.happy_students;
        state.online_courses = res.online_courses;
        state.professors = res.professors;
        //state.gallery_show = res.gallery_show;
    },
    
}

export default {
    state,
    getters,
    actions,
    mutations
};