import Vue from 'vue'
import VueRouter from 'vue-router'
import store from './store/store'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        component: () => import("./views/Index.vue"),    
        children: [
            {
                path: "/home",
                redirect: '/'
            },
            {
                path: "/",
                name: 'Home',
                meta: {
                    pageType: 'landing',
                    layout: 'Default'
                },
                component: () => import("./views/Home.vue")
            }            
        ]
    },     
    {
        path: "/account",        
        name: 'Account',
        meta: {
          pageType: 'inside',
          slug: 'account'
        },
        component: () => import("./views/Account.vue"),
        children: [
            {
                name: 'Login',
                path: "/account/login",
                meta: {
                    slug: 'login'
                }
            },
            {
                name: 'Registration',
                path: "/account/registration",
                meta: {
                    slug: 'registration'
                }
            },
            {
                name: 'Forgot Password',
                path: "/account/forgot-password",
                meta: {
                    slug: 'forgot-password'
                }
            },
            {
                name: 'Reset Password',
                path: "/account/reset-password",
                meta: {
                    slug: 'reset-password'
                }
            }
        ]        
    },
    {
        path: "/contact-us",        
        name: 'Contact',
        meta: {
          pageType: 'inside',
          slug: 'contact-us'
        },
        component: () => import("./views/Contact.vue")
    },
    {
        path: "/courses",        
        name: 'Course',
        meta: {
          pageType: 'inside',
          slug: 'courses'
        },
        component: () => import("./views/Course.vue")
    },
    {
        path: "/gallery",        
        name: 'gallery',
        meta: {
          pageType: 'inside',
          slug: 'gallery'
        },
        component: () => import("./views/Gallery.vue"),
        children: [
            {
                name: 'galleryDetails',
                path: "/gallery/:id/photos",
                meta: {
                    slug: 'gallery-details'
                }
            }            
        ]
    },
    {
        path: "/blog",        
        name: 'Blog',
        meta: {
          pageType: 'inside',
          slug: 'blog'
        },
        component: () => import("./views/Blog.vue"),
        children: [
            {
                name: 'blogDetails',
                path: "/blog/:id/post",
                meta: {
                    slug: 'blog-details'
                }
            },
            
        ]
    },
    {
        path: "/user",        
        name: 'User',
        meta: {
          pageType: 'inside',
          slug: 'user'
        },
        component: () => import("./views/User.vue"),
        children: [
            {
                name: 'Dashboard',
                path: "/user/dashboard",
                meta: {
                    authRequired: true,
                    slug: 'dashboard',                    
                    layout: 'UserLayout'
                }
            },
            {
                name: 'Profile',
                path: "/user/profile",
                meta: {
                    authRequired: true,
                    slug: 'profile',                    
                    layout: 'UserLayout'
                }
            },
            {
                name: 'ChangePassword',
                path: "/user/change-password",
                meta: {
                    authRequired: true,
                    slug: 'change-password',                    
                    layout: 'UserLayout'
                }
            },
            {
                name: 'UserCourse',
                path: "/user/course/:id",
                meta: {
                    authRequired: true,
                    slug: 'course-view',                    
                    layout: 'UserLayout'
                }
            },
            {
                name: 'courseVideo',
                path: "/user/course/:id/video",
                meta: {
                    authRequired: true,
                    slug: 'course-video',                    
                    layout: 'UserLayout'
                }
            },
            {
                name: 'UserCourseCategory',
                path: "/user/category/:categoryid/course",
                meta: {
                    authRequired: true,
                    slug: 'dashboard',                    
                    layout: 'UserLayout'
                }
            },
            {
                name: 'UserFavCourse',
                path: "/user/favourite-courses",
                meta: {
                    authRequired: true,
                    slug: 'favourite-courses',                    
                    layout: 'UserLayout'
                }
            },
            {
                name: 'BankDetails',
                path: "/user/bank-details",
                meta: {
                    authRequired: true,
                    slug: 'bank-details',                    
                    layout: 'UserLayout'
                }
            }
        ]
    },
    {
        path: "/category/:id/courses",        
        name: 'Category',
        meta: {
          pageType: 'inside',
          slug: 'category'
        },
        component: () => import("./views/Course.vue")
    },
    {
        path: "/video",        
        name: 'Video',
        meta: {
          pageType: 'inside',
          slug: 'video'
        },
        component: () => import("./views/Demo.vue")
    },
    {
        path: "/about",        
        name: 'About',
        meta: {
          pageType: 'inside',
          slug: 'about-us'
        },
        component: () => import("./views/Page.vue"),
        children: [
            {
                path: "/about",
                redirect: '/404'
            },
            {
                name: 'AboutUs',
                path: "/about/:slug",
                meta: {
                    slug: 'about-us'
                }
            },
        ]
    },
    {
        path: "/forum",        
        name: 'Forum',
        meta: {
          pageType: 'inside',
          slug: 'forum-list'
        },
        component: () => import("./views/Forum.vue"),
        children: [
            {
                name: 'ForumCategory',
                path: "/forum/:categoryid/category",
                meta: {
                    slug: 'forum-list'
                }
            },
            {
                name: 'ForumDetails',
                path: "/forum/:id/details",
                meta: {
                    slug: 'forum-details'
                }
            },
            
        ]
    },
    {
        path: "/*",
        name: '404',
        meta: {
          pageType: 'inside'
        },
        component: () => import("./views/404.vue")
    }
]

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes
});

router.beforeEach((to, from, next) => {
    window.scrollTo(0, 0);
    const authRequired = to.matched.some((route) => route.meta.authRequired)
    if (!authRequired) return next()
    if(store.state.auth.token) {
        next()
    }
    else {
        next({ name: 'Login'})
    }
});
export default router