import axios from 'axios'

const authToken = localStorage.getItem('_howofwhat_token') || ''

var baseurl;
if(location.hostname === 'localhost') {
  // baseurl = 'http://localhost/howofwhat_backend/api/';
  baseurl = 'https://howofwhat.com/api/';
} else {
  baseurl = 'https://howofwhat.com/api/';
}

const instance = axios.create({
    baseURL: baseurl,
    timeout: 50000,
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Access-Control-Allow-Origin': '*',
        'Default-Langugage': localStorage.getItem('_store_lang') || 'en',
        'Authorization': 'Bearer ' + authToken
    }
});
instance.interceptors.response.use(response => {
    return response;
  }, error => {   
    //console.log(error.response)   
   if (error.response.status === 401) {
        //alert(error.response.data.error)
   }
   throw error;
//    return error.response.data.error;
  });

export default instance;