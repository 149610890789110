import Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// vuelidate
import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

// Vuex (Store)
import store from './store/store'

import axios from "./axios.js"
Vue.prototype.$http = axios

//lazysizes
import 'lazysizes';
// import a plugin
import 'lazysizes/plugins/parent-fit/ls.parent-fit';

// Sweet Alert
import VueSweetalert2 from 'vue-sweetalert2';
Vue.use(VueSweetalert2);

// Fragment
import Fragment from 'vue-fragment'
Vue.use(Fragment.Plugin)

//Vue.prototype.$baseURL = process.env.NODE_ENV === 'production' ? '/howofwhat-new/' : '/',
Vue.prototype.$baseURL = '/'

//import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
// Install BootstrapVue
//Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
//Vue.use(IconsPlugin)


Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
