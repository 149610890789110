import axios from "../../axios.js";

const state = {
    pageData: [],
    dir_path: ''
}

const actions = {
    getPageData: ({commit}, payload)=>{
        return new Promise((resolve, reject)=>{
            axios.get(`page/${payload}`)
            .then(res=> {
                if(res.status === 200) {
                    resolve(res)
                    commit('setPageData', res.data)
                }
            })
            .catch(err=>{
                reject(err)
            })
        })
    }
}

const mutations = {
    setPageData: (state, res)=>{
        state.pageData = res.data,
        state.dir_path = res.dir_path
    }
}

export default {
    state, actions, mutations
}