import axios from "../../axios.js";

const state = {
    socialData: []
}

const actions = {
    getSocialLinks: ({commit})=>{
        return new Promise((resolve, reject)=>{
            axios.get(`socialmedia`).then(res=> {
                if(res.status === 200) {
                    resolve(res)
                    commit('setSocialMediaData', res.data)
                }
            })
            .catch(err=>{
                reject(err)
            })
        })
    }
}

const mutations = {
    setSocialMediaData: (state, res)=>{
        state.socialData = res.data
    }
}

export default {
    state, actions, mutations
}