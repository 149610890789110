
import Vue from "vue";
import Vuex from "vuex";
import configuration from "./modules/configuration";
import auth from "./modules/auth";
import category from "./modules/category";
import course from "./modules/course";
import social from "./modules/social";
import page from "./modules/page";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
	modules: {    
		configuration,
		auth,
		category,
		course,
		social,
		page
	},
	strict: debug
});
