import axios from "../../axios.js";

const state = {
    courseCategories: []
}

const actions = {
    courseCategories: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            axios.get(`/categories`, payload).then(res=>{
                if(res.status === 200) {
                    resolve(res)
                    commit('setCategories', res.data)
                }
            })
            .catch(err=>{
                reject(err)
            })
        })
    },
    userCourseCategories: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            axios.get(`account/user/categories`, payload).then(res=>{
                // console.log(res)
                if(res.status === 200) {
                    resolve(res)
                    commit('setCategories', res.data)
                }
            })
            .catch(err=>{
                reject(err)
            })
        })
    }
}

const mutations = {
    setCategories: (state, res)=>{
        state.courseCategories = res.data
    }
}

export default {
    state, actions, mutations
}