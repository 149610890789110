<template>
	<div id="app">
		<component :is="layout">
			<router-view :layout.sync="layout"></router-view>
		</component>
	</div>
</template>

<script>

export default {
	name: 'App',
	data() {
		return {
			routeLayout: 'Default',
			specialAccount: this.$store.state.auth.isSpecial || 'N',
			defaultLang: localStorage.getItem('_store_lang') || 'en'
		}
	},
	computed: {
        layout(){
			let routeLayout = this.routeLayout     
            return () => import(`@/layout/${routeLayout}`);
        }
	},
	methods: {
		setPageTitle() {
			document.querySelector('title').textContent = this.$store.state.configuration.siteTitle
		},		
        setStyle() {
            if(this.specialAccount === 'Y') {
                document.querySelector('html').classList.add('special-user')
            }
		}
	},
	created: function(){
		if(this.defaultLang == 'ar') {
			document.body.classList.add('rtl')
		}
		if(typeof this.$route.meta.layout !== 'undefined' && this.$route.meta.layout) {
			this.routeLayout = this.$route.meta.layout
		}
		this.$store.dispatch('getConfigData')	
		this.$store.dispatch('validateUser')	
		this.$store.dispatch(`getSocialLinks`)
		this.setPageTitle()
		this.setStyle()
	},
	watch: {
		"$route.meta.layout":function(){
			if(typeof this.$route.meta.layout !== 'undefined' && this.$route.meta.layout) {
				this.routeLayout = this.$route.meta.layout
			}
		},
		"$store.state.configuration.siteTitle": function() {
			this.setPageTitle()
		},
		"$store.state.auth.isSpecial": function() {
            this.specialAccount = this.$store.state.auth.isSpecial
            this.setStyle()
        }
	}
}
</script>
