import axios from "../../axios.js";

const state = {
    courseData: [],
    token: localStorage.getItem('_howofwhat_token') || null
}

const mutations = {
    setCourses: (state, res) => {
        state.courseData = res.data
    }
}

const actions = {
    getCourses: ({commit}, payload) =>{
        return new Promise((resolve, reject)=>{
            let remotePath = `/course`;
            if(state.token !== null) {
                remotePath = `/account/course`;
            }
            axios.get(remotePath, payload).then(res=>{
                if(res.status === 200) {
                    resolve(res)
                    commit('setCourses', res.data)
                }
            })
            .catch(err=>{
                reject(err)
            })
        })
    },
    getLatestCourses: ({commit}, payload) =>{
        return new Promise((resolve, reject)=>{
            axios.get(`/course/latest-courses`, payload).then(res=>{
                if(res.status === 200) {
                    resolve(res)
                    commit('setCourses', res.data)
                }
            })
            .catch(err=>{
                reject(err)
            })
        })
    },
    userCourses: ({commit}, payload) =>{
        return new Promise((resolve, reject)=>{
            axios.get(`account/user/courses`, payload).then(res=>{
                if(res.status === 200) {
                    resolve(res)
                    commit('setCourses', res.data)
                }
            })
            .catch(err=>{
                reject(err)
            })
        })
    },
    favouriteCourses: ({commit}, payload) =>{
        return new Promise((resolve, reject)=>{            
            axios.get(`account/user/get-favourite-course`, payload).then(res=>{
                if(res.status === 200) {
                    resolve(res)
                    commit('setCourses', res.data)
                }
            })
            .catch(err=>{
                reject(err)
            })
        })
    },
    userLatestCourses: ({commit}, payload) =>{
        return new Promise((resolve, reject)=>{
            axios.get(`account/user/latest-courses`, payload).then(res=>{
                if(res.status === 200) {
                    resolve(res)
                    commit('setCourses', res.data)
                }
            })
            .catch(err=>{
                reject(err)
            })
        })
    }
}

export default {
    state, actions, mutations
}